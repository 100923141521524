import { Dialog } from "@ariakit/react";
import { Link } from "@remix-run/react";
import { X } from "lucide-react";
import type { Collector } from "~/types";
import { cn } from "~/util/css";

interface CollectorCardProps {
  collector: Collector;
  index: number;
}

export function CollectorCard(props: CollectorCardProps) {
  const { collector, index } = props;
  return (
    <Link to={`/${collector?.username}`} key={index}>
      <div className="rounded-lg bg-[linear-gradient(84deg,#D7D9FF_0%,#D5D7FF_124.78%)] px-4 py-2">
        <div className="flex items-center gap-2 h-16">
          {[0, 1, 2].includes(index) && (
            <div className="relative mt-1">
              <div
                className="w-[38px] h-[48px] bg-contain bg-no-repeat bg-center relative z-20"
                style={{
                  backgroundImage: `url('/rank-sm-${index + 1}.svg')`,
                }}
              />
              <div className="absolute top-1 left-1/2 -translate-x-1/2 w-[29px] h-[29px]">
                <img
                  src={collector?.avatar_url || "/default-avatar.svg"}
                  alt={""}
                  className="w-full h-full rounded-full z-10  transition-transform duration-300 ease-in-out group-hover:scale-105"
                />
              </div>
            </div>
          )}
          {![0, 1, 2].includes(index) && (
            <div
              className="relative flex items-center justify-center w-[38px] h-[38px] rounded-full bg-no-repea bg-center"
              style={{
                backgroundImage: "url(/avatar-stripe-border.svg)",
              }}
            >
              <img
                src={"/default-avatar.svg"}
                alt=""
                className="w-8 h-8 rounded-full bg-cover bg-center"
              />
              <div className="absolute w-6 h-5 flex items-center justify-center -bottom-[7px] rounded border border-[#023EF7] bg-black text-white text-xs font-medium">
                {index + 1}
              </div>
            </div>
          )}

          <div className="flex flex-col gap-1 flex-1 overflow-hidden">
            <p className="text-sm  truncate w-full font-medium">
              {collector?.username}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

interface CollectorsModalProps {
  open: boolean;
  onClose: () => void;
  collectors: Collector[];
}

export function CollectorsModal(props: CollectorsModalProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[60%] mx-auto overflow-hidden max-h-[90%]",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="bg-white rounded-xl border-primary border h-full">
        <div className="flex items-center justify-between p-4">
          <p className="font-bold text-lg">Collectors</p>
          <button onClick={props.onClose} type="button">
            <X />
          </button>
        </div>
        <div className="p-4 h-[calc(100%-60px)]">
          <div className="overflow-y-auto h-full">
            <div className="grid grid-cols-4 gap-3 items-center justify-center">
              {props.collectors?.map((collector, index) => (
                <CollectorCard
                  collector={collector}
                  key={collector.id}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
